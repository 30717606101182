import {FC, useRef, useState} from "react";
import style from "./TextField.module.scss";
import {clsx} from "clsx";
import {useField} from "formik";

interface ITextField {
    name: string
    label: string
    className?: string
    type?: string
}

export const TextField: FC<ITextField> = ({
                                              name,
                                              label,
                                              className,
                                              type,
                                              ...props
                                          }) => {
    const [field, meta, helpers] = useField(name);
    const [focus, setFocus] = useState(false)
    const ref = useRef<HTMLInputElement>(null!);

    return (
        <div className={clsx({
            [style.textField]: true,
            [style.textField_focus]: focus,
        }, Boolean(className) && className)}
             onClick={() => {
                 ref.current.focus();
                 //helpers.setTouched(true)
             }}
        >

            <p className={clsx({
                [style.label]: true,
                [style.label_focus]: focus || field.value,
            })}>
                {label}
            </p>


            <input {...props}
                   ref={ref}
                   name={field.name}
                   type={type || "number"}
                   value={field.value}
                   onChange={field.onChange}
                   onBlur={(e) => {
                       field.onBlur(e);
                       setFocus(false)
                   }}
                   onFocus={() => setFocus(true)}
                   className={clsx({
                       [style.input]: true,
                       [style.input_focus]: focus || field.value,
                   })}
            />

            {/*{*/}
            {/*    meta.touched && meta.error */}
            {/*}*/}

        </div>
    )
}