import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {svgIcons} from "../../../assets/svgIcons";
import {TextField} from "../../_common/TextField/TextField";
import {ButtonCustom} from "../../_common/ButtonCustom/ButtonCustom";
import {Link} from "react-router-dom";
import style from "./Buy.module.scss"
import {SelectField} from "../../C2_JoinTheFix/SelectField/SelectField";
import Slider from "@mui/material/Slider";
import {ThumbComponent} from "../../C7_EstimateYourInvestment/ThumbComponent/ThumbComponent";
import {useState} from "react";
import {ValueLabelComponent} from "./ValueLabelComponent/ValueLabelComponent";

interface IValues {
    receive: string
    pay: string
}

const initialValues: IValues = {
    receive: "",
    pay: "",
}

export const Buy = () => {
    const [value, setValue] = useState(30);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    const onSubmit = async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
        try {
            console.log(values);

        } catch (e) {
            console.log(e)
        } finally {

        }
    }

    return (
        <div className={style.buy}>

            <div className={style.title}>
                {
                    [
                        {
                            value: "$4,194,617.71",
                            label: "USDT Raised"
                        },
                        {
                            value: "5,806",
                            label: "Holders"
                        },
                    ].map(({value, label}, key) => (
                        <div key={key}
                             className={style.titleItem}
                        >
                            <p>{value}</p>
                            <p>{label}</p>
                        </div>
                    ))
                }

            </div>

            <p className={style.currencyTopMobile}>
                <span>10%</span> Increase On Next Phase
            </p>

            <div className={style.currencyDesktop}>
                <p>
                    <span>1 $FIX = </span>{svgIcons.usdt} 0.02829537 USD
                </p>
                <p>
                    <span>10%</span> Increase On Next Phase
                </p>
            </div>

            <div className={style.sliderWrapper}>
                <Slider value={value}
                        valueLabelDisplay="on"
                        onChange={handleChange}
                        slots={{
                            thumb: ThumbComponent,
                            valueLabel: ValueLabelComponent
                        }}
                        sx={{
                            padding: "0px 0px!important",
                            height: "28px",
                            borderRadius: "14px",
                            "& .MuiSlider-rail": {
                                backgroundColor: "#313332",
                                opacity: 1,
                            },
                            "& .MuiSlider-track": {
                                backgroundColor: "#f7fa5a",
                                border: "none",
                                borderRadius: 0,
                            },
                            "& .MuiSlider-thumb": {
                                width: "28px",
                                height: "28px",
                                backgroundColor: "transparent",
                                border: "none",
                                "&:hover": {
                                    boxShadow: "none",
                                },
                                "&::before": {
                                    boxShadow: "none",
                                },
                            },
                            "& .MuiSlider-thumb.Mui-focusVisible": {
                                boxShadow: "none",
                            },
                            "& .MuiSlider-thumb::after": {
                                display: "none"
                            },
                            "& .MuiSlider-valueLabel": {
                                padding: 0,
                            },

                        }}
                />

            </div>

            <div className={style.currencyBottomMobile}>
                <p>1 $FIX =</p>
                <p>0.02829537 USD</p>
            </div>

            <p className={style.info}>
                {svgIcons.infoCircle}
                <span>your purchased $FIX = 0</span>
            </p>

            <Formik initialValues={initialValues}
                    onSubmit={onSubmit}
            >
                {
                    (props: FormikProps<IValues>) => (
                        <Form autoComplete="off"
                              className={style.form}
                        >

                            <div className={style.fields}>

                                <div className={style.receiveField}>
                                    <TextField label="You pay"
                                               name="pay"
                                    />
                                    <div className={style.select}>
                                        <SelectField/>
                                    </div>
                                </div>

                                <TextField label="You Receive $FIX"
                                           name="Receive"
                                           className={style.receiveField}
                                />
                            </div>

                            <div className={style.btns}>

                                <div className={style.btnsRightPart}>
                                    <div className={style.leaderboard}>
                                        {svgIcons.award}<span>View Leaderboard</span>
                                    </div>
                                    <ButtonCustom label="Connect wallet"
                                                  type="submit"
                                                  className={style.connectWallet}
                                    />
                                </div>

                                <Link to="/"
                                      className={style.howToBuyLink}
                                >
                                    How to Buy
                                </Link>
                            </div>


                        </Form>
                    )
                }
            </Formik>


        </div>
    )
}