import React, {createContext, useRef} from 'react';
import {Route, Routes} from "react-router-dom";
import {HomePage} from "../B0_HomePage/HomePage";
import {PresalePage} from "../C1_PresalePage/PresalePage";
import {useStore} from "../../store/rootStore";
import style from "./App.module.scss";
import {Header} from "../A1_Header/Header";
import {Footer} from "../A2_Footer/Footer";
import {BurgerMenu} from "../A3_BurgerMenu/BurgerMenu";
import {observer} from "mobx-react-lite";
import {clsx} from "clsx";
import {Presale2} from "../D0_Presale2/Presale2";
import { Toaster } from 'react-hot-toast';

export const App = observer(() => {
    const {
        appStore: {
            burgerMenu
        }
    } = useStore();

    return (
                <div className={clsx({
                    [style.app]: true,
                    [style.app_burgerMenu]: burgerMenu,
                })}>
                    <Header/>
                    <BurgerMenu/>
                    <main>
                        <Routes>
                            <Route path="/" element={<HomePage/>}/>
                            <Route path="/presale" element={<PresalePage/>}/>
                            {/*<Route path="/presale2" element={<Presale2/>}/>*/}
                        </Routes>
                    </main>
                    {/*<Footer/>*/}
					<Toaster toastOptions={{position: "bottom-center", style: {
						backgroundColor: "#222",
						color: "#fff"
					}}} />
                </div>
    );
})

