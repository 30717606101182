import style from "./TabsCard.module.scss"
import {useState} from "react";
import {svgIcons} from "../../assets/svgIcons";
import {Tabs} from "./Tabs/Tabs";
import {Buy} from "./Buy/Buy";
import {Stake} from "./Stake/Stake";
import {Transactions} from "./Transactions/Transactions";

export const TabsCard = () => {

    const [tab, setTab] = useState(0);


    return (
        <div className={style.tabsCard}>
            <div className={style.inner}>

                <Tabs tab={tab}
                      onTab={(tab) => setTab(tab)}
                      className={style.tabsDesktop}
                />

                <div className={style.card}>
                    <Tabs tab={tab}
                          onTab={(tab) => setTab(tab)}
                          className={style.tabsMobile}
                    />

                    {tab === 0 && <Buy/>}
                    {tab === 1 && <Stake/>}
                    {tab === 2 && <Transactions/>}
                </div>

                {
                    tab === 0 && (
                        <div className={style.leaderboard}>
                            {svgIcons.award}<span>View Leaderboard</span>
                        </div>
                    )
                }

                <div className={style.contentBottom}>

                    <p className={style.descriptionTop}>
                        <span>First come, first serve</span> the <span>fastest contributors, get</span> the
                        highest <span>$FIX bonus</span> on top of their purchase
                    </p>

                    <div className={style.groups}>
                        {
                            [
                                {
                                    fix: "+30%",
                                    percent: "First 20%",
                                },
                                {
                                    fix: "+20%",
                                    percent: "21% - 30%",
                                },
                                {
                                    fix: "+15%",
                                    percent: "31% - 40%",
                                },
                                {
                                    fix: "+10%",
                                    percent: "41% - 50%",
                                },
                                {
                                    fix: "+5%",
                                    percent: "51% - 100%",
                                },
                            ].map(({fix, percent}, key) => (
                                <div key={key}
                                     className={style.group}
                                >

                                    <div className={style.left}>
                                        <p>
                                            Group {key + 1}
                                        </p>
                                        <p>
                                            <span>$FIX </span><span>{fix}</span>
                                        </p>
                                    </div>

                                    <div className={style.right}>
                                        <p>
                                            // {percent}
                                        </p>
                                        <p>Pre-Sale transactions</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <p className={style.descriptionBottom}>
                        If in total 10,000 transactions contributed to $FIX pre-sale and you are amongst the first
                        2,000 (20%), then you get 30% bonus. If for example you are the 4123th transaction you get
                        10% bonus etc
                    </p>

                </div>


            </div>
        </div>
    )
}