import style from "./JoinTheFix3.module.scss"
import back from "../../assets/png/WeFund/background.png";
import {svgIcons} from "../../assets/svgIcons";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {TextField} from "../_common/TextField/TextField";
import {ButtonCustom} from "../_common/ButtonCustom/ButtonCustom";
import {Lines} from "../_common/Lines/Lines";
import {useEffect, useState} from "react";
import {convertToTwoDigit, getDays, getHours, getMins, getSecs} from "../../helpers/timer";
import animationData from "../../assets/json/data.json";
import Lottie from "lottie-react";
import toast from "react-hot-toast";

interface IValues {
    email: string
}

const initialValues: IValues = {
    email: "",
}

const dateEnd = new Date(2025, 0, 1, 0, 0, 0); // дата окончания


export const getApiErrorMessage = (e: unknown, defaultMsg?: string): string => {
	if (typeof(e) === "string") return e;
	if (typeof(e) === "object" && e && "message" in e && typeof(e.message) === "string") {
		const errors = e.message.split(" | ")
		const error = errors[0]
		if (typeof(error) === "string" && !error.includes("Message: ")) {
			if (error.includes("Details: ")) return error.split("Details: ")[1].split("\n")[0]
			return error
		}
		const msg = error.split("Message: ")[1] ?? defaultMsg
		let path = (error.split("Path: ")[1] ?? "").split(" ~ ")[0]
		path = path.split("body.")[1] ?? path
		path = path.split("_").map((str) => str ? str[0].toUpperCase() + str.slice(1) : "").join(" ")

		if (path) return `${path} - ${msg}`
		return msg ?? defaultMsg
	}
	return defaultMsg ?? "Internal server error"
}

export const JoinTheFix3 = () => {

	const [ submitting, setSubmitting ] = useState(false)
    const onSubmit = async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
		if (submitting) return
		if (!values.email.includes("@")) return toast.error("Invalid email")
		setSubmitting(true)
		try {
			await toast.promise(new Promise(async (resolve, reject) => {
				try {
					const res = await fetch("https://api.presale.gg/v1/projects/fundfix/leads", {
						method: "POST",
						body: JSON.stringify({email: values.email}),
						headers: {
							"Content-Type": "application/json"
						}
					})
					const data = await res.json()
					if (!res.ok) return reject(data)
					resolve(data)
				} catch (err) {
					reject(err)
				}
			}), {
				loading: "Submitting details",
				error: (err) => getApiErrorMessage(err, "Error submitting details"),
				success: "Successfully submitted details"
			})
		} catch(_) {}
		setSubmitting(false)
    }

    // timer
    const [timeIsOver, setTimeIsOver] = useState(false);
    const [time, setTime] = useState(0);
    const [start, setStart] = useState(false);

    useEffect(() => {
        const time = new Date(dateEnd.getTime() - new Date().getTime()).getTime();
        if (time > 0 && !timeIsOver) {
            setTime(time);
            setStart(true);
        }
    }, []);

    useEffect(() => {
        const timeId = setTimeout(() => {
            if (start) {
                if (time <= 60000) {
                    setTimeIsOver(true);
                    clearTimeout(timeId);
                } else {
                    setTime(time => time - 1000);
                }
            }
        }, 1000);
        return () => {
            clearTimeout(timeId);
        }
    }, [time]);

    return (
        <div className={style.joinTheFix3}>

            <div className={style.linesWrapper}>
                <Lines count={4}/>
            </div>

            <img src={back} alt="" className={style.back}/>

            <div className={style.inner}>

                <h1 className={style.title}>
                    <p>
                        Join the $FIX
                    </p>
                    <p>
                        Presale Whitelist
                    </p>
                </h1>

                <div className={style.lottieWrapper}>
                    <Lottie animationData={animationData}
                            autoPlay={true}
                            loop={false}
                    />
                </div>

                <div className={style.content}>

                    <div className={style.card}>

                        <p className={style.cardTitle}>
                            presale will launch in
                        </p>

                        <div className={style.progress}>
                            <div className={style.progressValue}/>
                        </div>

                        <div className={style.timerValues}>
                            {
                                [
                                    {
                                        value: convertToTwoDigit(getDays(time)),
                                        label: "Days",
                                    },
                                    {
                                        value: convertToTwoDigit(getHours(time)),
                                        label: "Hours",
                                    },
                                    {
                                        value: convertToTwoDigit(getMins(time)),
                                        label: "Minutes",
                                    },
                                    {
                                        value: convertToTwoDigit(getSecs(time)),
                                        label: "Seconds",
                                    },
                                ].map(({value, label}, key) => (
                                    <div key={key}
                                         className={style.timerItem}
                                    >
                                        <p>{value}</p>
                                        <p>{label}</p>

                                    </div>
                                ))
                            }
                        </div>

                        <Formik initialValues={initialValues}
                                onSubmit={onSubmit}
                        >
                            {
                                (props: FormikProps<IValues>) => (
                                    <Form autoComplete="off"
                                          className={style.form}
                                    >

                                        <div className={style.fieldWrapper}>
                                            <TextField label="Enter your email"
                                                       name="email"
                                                       type="text"

                                            />
                                            <button className={style.submitBtnMobile}
                                                    type="submit"
                                            >
                                                {svgIcons.send}
                                            </button>
                                        </div>

                                        <ButtonCustom label="Submit Details"
                                                      type="submit"
                                                      className={style.submitBtnDesktop}
                                        />

                                    </Form>
                                )
                            }
                        </Formik>

                        <p className={style.info}>
                            Get exclusive <span>early access</span> to the FundFix presale
                        </p>

                        <div className={style.socialLinks}>
                            {
                                [
                                    {
                                        href: "https://t.me/+9oL18_ogzuNkNWU8",
                                        icon: svgIcons.telegram
                                    },
                                    {
                                        href: "https://x.com/FundFix_AI",
                                        icon: svgIcons.x_outlined
                                    },

                                ].map(({href, icon}, key) => (
                                    <a key={key}
                                       href={href}
                                       target="_blank"
                                       rel="noreferrer noopener nofollow"
                                    >
                                        {icon}
                                    </a>
                                ))
                            }
                        </div>

                    </div>

                    <div className={style.itemsSection}>
                        {
                            [
                                {
                                    label: "Secure Your Spot",
                                    text: "By joining the whitelist, you'll secure your spot to buy tokens at the lowest price before they go public."
                                },
                                {
                                    label: "Get Priority Benefits",
                                    text: "Enjoy priority allocation and special bonuses for being part of our early supporters. Don’t miss out—limited spots available. Sign up now to lock in your advantage!"
                                },
                            ].map(({label, text}, key) => (
                                <div key={key}
                                     className={style.item}
                                >
                                    <p>{label}</p>
                                    <p>{text}</p>
                                </div>
                            ))
                        }
                    </div>


                </div>


            </div>
        </div>
    )
}