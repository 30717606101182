import {WeFund} from "../B1_WeFund/WeFund";
import {MadeByTraders} from "../B2_MadeByTraders/MadeByTraders";
import {WhoWeAre} from "../B3_WhoWeAre/WhoWeAre";
import {WeNeverRunOut} from "../B4_WeNeverRunOut/WeNeverRunOut";
import {FixToken} from "../B5_FixToken/FixToken";
import {WhatsNext} from "../B6_WhatsNext/WhatsNext";
import {CreateAnAccount} from "../B7_CreateAnAccount/CreateAnAccount";
import {HavingTrouble} from "../B8_HavingTrouble/HavingTrouble";
import {JoinTheFix2} from "../D1_JoinTheFix2/JoinTheFix2";
import {JoinTheFix3} from "../D2_JoinTheFix3/JoinTheFix3";

export const HomePage = () => {
    return (
        <div>
            {/*<WeFund/>*/}
            {/*<JoinTheFix2/>*/}
            <JoinTheFix3/>
            {/*<MadeByTraders/>*/}
            {/*<WhoWeAre/>*/}
            {/*<WeNeverRunOut/>*/}
            {/*<FixToken/>*/}
            {/*<WhatsNext/>*/}
            {/*<CreateAnAccount/>*/}
            {/*<HavingTrouble/>*/}
        </div>
    )
}