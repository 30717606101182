import style from "./Phase11.module.scss"
import {svgIcons} from "../../assets/svgIcons";import {Lines} from "../_common/Lines/Lines";
import back from "../../assets/png/WeFund/background.png";
import {useEffect, useState} from "react";
import {convertToTwoDigit, getDays, getHours, getMins, getSecs} from "../../helpers/timer";

const dateEnd = new Date(2025, 0, 1, 0, 0, 0); // дата окончания


export const Phase11 = () => {

    // timer
    const [timeIsOver, setTimeIsOver] = useState(false);
    const [time, setTime] = useState(0);
    const [start, setStart] = useState(false);

    useEffect(() => {
        const time = new Date(dateEnd.getTime() - new Date().getTime()).getTime();
        if (time > 0 && !timeIsOver) {
            setTime(time);
            setStart(true);
        }
    }, []);

    useEffect(() => {
        const timeId = setTimeout(() => {
            if (start) {
                if (time <= 60000) {
                    setTimeIsOver(true);
                    clearTimeout(timeId);
                } else {
                    setTime(time => time - 1000);
                }
            }
        }, 1000);
        return () => {
            clearTimeout(timeId);
        }
    }, [time]);

    return (
        <div className={style.phase11}>

            <div className={style.linesWrapper}>
                <Lines count={4}/>
            </div>

            <img src={back} alt="" className={style.back}/>

            <div className={style.inner}>

                <h1 className={style.title}>
                    <p>
                        Phase 11
                    </p>
                </h1>

                <p className={style.label}>
                    Next phase begins in
                </p>

                <div className={style.timer}>
                    {
                        [
                            {
                                value: convertToTwoDigit(getDays(time)),
                                label: "Days",
                            },
                            {
                                value: convertToTwoDigit(getHours(time)),
                                label: "Hours",
                            },
                            {
                                value: convertToTwoDigit(getMins(time)),
                                label: "Minutes",
                            },
                            {
                                value: convertToTwoDigit(getSecs(time)),
                                label: "Seconds",
                            },
                        ].map(({value, label}, key) => (
                            <div key={key}
                                 className={style.timerItem}
                            >
                                <p>{value}</p>
                                <p>{label}</p>

                            </div>
                        ))
                    }
                </div>


            </div>


        </div>
    )
}