import {svgIcons} from "../../../assets/svgIcons";
import {clsx} from "clsx";
import style from "./Tabs.module.scss"
import {FC} from "react";

interface ITabs {
    tab: number
    onTab: (tab: number) => void
    className?: string
}

export const Tabs: FC<ITabs> = ({
                                    tab,
                                    onTab,
                                    className
                                }) => {
    return (
        <div className={clsx(style.tabs, Boolean(className) && className)}>
            {
                [
                    {
                        icon: svgIcons.coins,
                        label: "Buy",
                    },
                    {
                        icon: svgIcons.lock,
                        label: "Stake",
                    },
                    {
                        icon: svgIcons.clock,
                        label: "History",
                    },
                ].map(({icon, label}, key) => (
                    <button key={key}
                            className={clsx({
                                [style.tab]: true,
                                [style.tab_active]: key === tab,
                            })}
                            onClick={() => onTab(key)}
                    >
                        {icon}<p>{label}</p>
                    </button>
                ))
            }
        </div>
    )
}