import style from "./Transactions.module.scss"
import {data} from "./data";
import {svgIcons} from "../../../assets/svgIcons";
import {clsx} from "clsx";

export const Transactions = () => {
    return (
        <div className={style.transactions}>

            <p className={style.cardTitle}>
                Transactions
            </p>

            <div className={style.list}>
                {
                    data.map(({price, amount, status}, key) => (
                        <div key={key}
                             className={style.item}
                        >
                            <div className={style.icon}>
                                {svgIcons.coins}
                            </div>
                            <div className={style.right}>
                                <p>{price}</p>
                                <p>{amount}</p>
                                <p className={clsx({
                                    [style.status]: true,
                                    [style.status_completed]: status === "Completed",
                                })}>
                                    {status}
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>
    )
}